<template>
  <div class="login">
    <div class="login_box" v-if="register === 1">

      <div class="login_tab">
        <ul v-if="loginType != 2">
          <li
            @click="loginTypeClick(0)"
            :class="loginType == 0 ? 'active' : ''"
          >
            {{ $t("login.SignInByPassword") }}
          </li>
          <li
            @click="loginTypeClick(1)"
            :class="loginType == 1 ? 'active' : ''"
          >
            {{ $t("login.SignInByPhone") }}
          </li>
        </ul>
        <div class="wx-login" @click="loginTypeClick(2)" v-if="loginType != 2">
          <p>{{ $t("login.SignInByWechat") }}</p>
          <img src="../assets/imgaes/login-rwm.png" alt="" />
        </div>
        <div
          class="wx-login pwd-login"
          @click="loginTypeClick(0)"
          v-if="loginType == 2"
        >
          <p>{{$t('login.SignInByPassword')}}</p>
          <img src="../assets/imgaes/pwd-login.png" alt="" />
        </div>
      </div>
      <div class="login-type">
        <div class="login-pwd" v-if="loginType == 0">
          <q-form ref="myForm" @submit="submitForm" @reset="onReset">
            <q-input
              borderless
              bottom-slots
              class="gt-sm"
              v-model="login.account"
              lazy-rules
              :rules="[verAccount]"
            >
              <template v-slot:prepend>
                <span class="input-lable">{{$t('login.AccountNumberorPhone')}}*</span>
              </template>
              <template v-slot:hint>
                <span class="input-hint">{{$t('login.EnterAccountNumberorPhone')}}</span>
              </template>
            </q-input>
            <q-input
                    color="grey-10"
                    borderless
                    bottom-slots
                    class="lt-md"
                    v-model="login.account"
                    :rules="[verAccount]"
                    :label="$t('login.AccountNumberorPhone')+'*'"
            >
              <template v-slot:hint>
                <span class="input-hint">{{$t('login.EnterAccountNumberorPhone')}}</span>
              </template>
            </q-input>
            <q-input
              class="gt-sm"
              borderless
              bottom-slots
              type="password"
              v-model="login.password"
              lazy-rules
              :rules="[verInputPwd]"
            >
              <template v-slot:prepend>
                <span class="input-lable">{{ $t("login.Password") }}*</span>
              </template>
              <template v-slot:hint>
                <span class="input-hint">{{$t('register.EnterYourPassword')}}</span>
              </template>
            </q-input>
            <q-input
                    color="grey-10"
                    borderless
                    bottom-slots
                    class="lt-md"
                    type="password"
                    v-model="login.password"
                    lazy-rules
                    :rules="[verInputPwd]"
                    :label="$t('login.Password')+'*'"
            >
              <template v-slot:hint>
                <span class="input-hint">{{$t('register.EnterYourPassword')}}</span>
              </template>
            </q-input>
            <q-btn
              :loading="loading"
              unelevated
              class="button-login"
              :label="$t('login.LogIn')"
              type="submit"
            ></q-btn>
          </q-form>
        </div>
        <div class="login-phone" v-if="loginType == 1">
          <q-form ref="myForm" @submit="submitForm" @reset="onReset">
            <q-input
              borderless
              bottom-slots
              type="tel"
              v-model="mobile"
              lazy-rules
              :rules="[verPhone]"
            >
              <template v-slot:prepend>
                <span class="input-lable">{{$t('login.PhoneNumber')}}*</span>
              </template>
              <template v-slot:hint>
                <span class="input-hint">请输入您的手机号</span>
              </template>
            </q-input>
            <q-input
              borderless
              bottom-slots
              type="password"
              v-model="newPwd"
              lazy-rules
              :rules="[verOldPwd]"
            >
              <template v-slot:prepend>
                <span class="input-lable">手机验证码*</span>
              </template>
              <template v-slot:append>
                <q-btn
                  @click="getPhoneCode()"
                  slot="append"
                  outline
                  class="code"
                  :label="codeText"
                />
              </template>

              <template v-slot:hint>
                <span class="input-hint">请输入您的手机验证码</span>
              </template>
            </q-input>
            <q-btn
              :loading="loading"
              unelevated
              class="button-login"
              :label="$t('login.LogIn')"
              type="submit"
            ></q-btn>
          </q-form>
        </div>
        <div class="login-wx" v-if="loginType == 2">
          <p>{{$t('login.PleaseScanTheCodeLogIntoMusicalTheorySystem')}}</p>
          <div class="ewm-box"></div>
          <div class="login-wx-btn">
            <div @click="loginType = 0">{{$t('login.SignInByPassword')}}</div>
            <span></span>
            <div @click="register = 2">{{$t('register.CreateNewAccount')}}</div>
          </div>
        </div>
      </div>

      <div class="login-bot-tab" v-if="loginType != 2">
        <div class="login-bot-tab-btn" @click="register = 2">{{$t('register.CreateNewAccount')}}</div>
        <div class="login-bot-tab-btn" @click="register = 3">{{$t('login.ForgotEmail')}}</div>
      </div>
    </div>
    <!--注册-->
    <register v-if="register === 2" />
    <!--忘记密码-->
    <ForgetPassword v-if="register === 3" />
  </div>
</template>

<script>
import {
  account,
  verOldPwd,
  verNewPwd,
  verConfirmPwd,
  phone,
} from "@/assets/Validation";
import register from "@/components/register";
import ForgetPassword from "@/components/ForgetPassword";
export default {
  name: "login",
  components: {
    register,
    ForgetPassword,
  },
  data() {
    return {
      register: 1, // 是否是注册页面
      loading: false, // 登录加载状态
      loginType: 0, // 登录方式 0 密码登录 1手机号 2微信扫码
      codeText: this.$t('login.GetCode'),
      ruleForm: {
        phone: "",
        code: "",
      },
      rules: {
        phone: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          { validator: phone, trigger: "blur" },
        ],
        code: [
          { required: true, message: "请输入验证码", trigger: "blur" },
          { type: "number", message: "请输入数字验证码" },
        ],
      },
      identity: null,
      options: [
        { label: "老师", value: "0" },
        { label: "学生", value: "1" },
      ],
      mobile: null,
      pwd: true,
      name: "",
      oldPwd: "",
      age: null,
      accept: false,
      newPwd: "",
      confirmPwd: "",
      login: {
        account: "",
        password: "",
      },
    };
  },
  methods: {
    // 登录方式选择
    loginTypeClick(index) {
      console.log(index);
      this.loginType = index;
    },
    // 获取手机验证码
    getPhoneCode() {
      let that = this;
      // eslint-disable-next-line no-unused-vars
      let times = null;
      // eslint-disable-next-line no-empty
      if (this.codeText == this.$t('login.GetCode')) {
        this.codeText = 5;
        times = setInterval(() => {
          that.codeText--;
          console.log(that.codeText);
          if (that.codeText === -1) {
            clearInterval(times);
            that.codeText = this.$t('login.GetCode');
          }
        }, 1000);

        // eslint-disable-next-line no-empty
      }
    },
    // 表单提交
    submitForm() {
      this.$refs.myForm.validate().then((success) => {
        if (success) {
          this.$http
            .post("user/login", {
              account: this.login.account,
              password: this.login.password,
            })
            .then((res) => {
              console.log(res);
              localStorage.setItem("token", res.data.userinfo.token);
              localStorage.setItem("user_id", res.data.userinfo.user_id);
              this.$q.notify({
                type: "positive",
                position: "top",
                timeout: "500",
                message: this.$t('home.SignInSuccessfully'),
              });
              this.$router.push({ path: "/" });
            })
            .catch((err) => {
              this.$q.notify({
                type: "negative",
                position: "top",
                timeout: "500",
                message: err.msg,
              });
            });
        } else {
          console.log("11111");
        }
      });
    },
    onSubmit() {
      console.log(this.identity.value);
      this.$refs.myForm.validate().then((success) => {
        if (success) {
          this.$http
            .post("user/register", {
              identity: this.identity.value,
              username: this.name,
              password: this.newPwd,
              repassword: this.newPwd,
              mobile: this.mobile,
            })
            .then((res) => {
              console.log("注册接口", res);
              localStorage.setItem("token", res.data.userinfo.token);
              localStorage.setItem("user_id", res.data.userinfo.user_id);
              this.$router.push({ path: "/" });
            });
          // 是的，模型是正确的
        } else {
          // 哦，不，用户至少
          // 填写了一个无效值
          console.log("11111");
        }
      });
    },
    verAccount(val) {
      return new Promise((resolve) => {
        if (!account(val)) {
          resolve(!!val || this.$t('login.EnterAccountNumberorPhone'));
        } else {
          resolve();
        }
      });
    },
    verOldPwd(val) {
      return new Promise((resolve) => {
        if (!verOldPwd(val)) {
          resolve(!!val || "请输入原密码");
        } else {
          resolve();
        }
      });
    },
    verInputPwd(val) {
      return new Promise((resolve) => {
        if (!verOldPwd(val)) {
          resolve(!!val || this.$t('register.EnterYourPassword'));
        } else {
          resolve();
        }
      });
    },
    verNewPwd(val) {
      return new Promise((resolve) => {
        if (!verNewPwd(val)) {
          resolve(!!val || "请输入新密码");
        } else {
          resolve();
        }
      });
    },
    verConfirmPwd(val) {
      return new Promise((resolve) => {
        console.log(verConfirmPwd(val, this.newPwd));
        if (verConfirmPwd(val, this.newPwd) === 0) {
          resolve("请再次输入新密码");
        } else if (verConfirmPwd(val, this.newPwd) === 1) {
          console.log("两次密码不一致");
          resolve("两次密码不一致");
        } else {
          resolve();
        }
      });
    },
    verPhone(val) {
      return new Promise((resolve) => {
        if (!phone(val)) {
          resolve(!!val || "请输入正确的手机号");
        } else {
          resolve();
        }
      });
    },
    onReset() {
      this.name = null;
      this.age = null;
      this.accept = false;
    },
  },
};
</script>

<style scoped lang="less">
</style>