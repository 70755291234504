<template>
    <div class="login_box" >
        <div class="login_tab zhuce_tab">
            <ul v-if="loginType !== 2">
                <li @click="loginTypeClick(0)" :class="loginType === 0 ?'active':''">邮箱验证</li>
                <li @click="loginTypeClick(1)" :class="loginType === 1 ?'active':''">手机验证</li>
            </ul>
            <div class="wx-login" @click="wxLogin(2)">
                 <p>{{$t('login.SignInByWechat')}}</p>
                <img src="../assets/imgaes/login-rwm.png" alt="">
            </div>
        </div>
        <div class="login-type" style="padding-bottom: 40px">
            <!--      用户注册-->
            <div v-if="loginType === 0">
                <q-form ref="myForm"
                        @submit="onSubmit"
                        @reset="onReset"
                >
                    <q-input borderless bottom-slots  v-model="name"  lazy-rules :rules="[ verEmail ]">
                        <template v-slot:prepend>
                            <span class="input-lable">邮箱账号*</span>
                        </template>
                        <template v-slot:hint>
                            <span class="input-hint">请输入邮箱账号</span>
                        </template>
                    </q-input>
                    <q-input borderless bottom-slots type="password"   v-model="newPwd"  lazy-rules :rules="[ verOldPwd ]">
                        <template v-slot:prepend>
                            <span class="input-lable">邮箱验证码*</span>
                        </template>
                        <template v-slot:append>
                            <q-btn @click="getPhoneCode()" slot="append" outline class="code" :label="codeText"/>
                        </template>

                        <template v-slot:hint>
                            <span class="input-hint">请输入您的邮箱验证码</span>
                        </template>
                    </q-input>
                    <q-input borderless bottom-slots type="password"   v-model="newPwd"  lazy-rules :rules="[ verOldPwd ]">
                        <template v-slot:prepend>
                            <span class="input-lable">新密码*</span>
                        </template>
                        <template v-slot:hint>
                            <span class="input-hint">请输入新密码</span>
                        </template>
                    </q-input>
                    <q-input borderless bottom-slots type="password"   v-model="confirmPwd"  lazy-rules :rules="[ verConfirmPwd ]">
                        <template v-slot:prepend>
                            <span class="input-lable">确认密码*</span>
                        </template>
                        <template v-slot:hint>
                            <span class="input-hint">请再次输入新密码</span>
                        </template>
                    </q-input>
                    <q-btn :loading="loading"  unelevated class="button-login" label="确认修改" type="submit"></q-btn>
                </q-form>
            </div>
            <div v-if="loginType === 1">
                <q-form ref="myForm" @submit="onSubmit" @reset="onReset">
                    <q-input borderless bottom-slots type="tel" v-model="mobile" lazy-rules :rules="[ verPhone ]">
                        <template v-slot:prepend>
                            <span class="input-lable">手机号*</span>
                        </template>
                        <template v-slot:hint>
                            <span class="input-hint">请输入您的手机号</span>
                        </template>
                    </q-input>
                  <q-input borderless bottom-slots type="password"   v-model="newPwd"  lazy-rules :rules="[ verOldPwd ]">
                    <template v-slot:prepend>
                      <span class="input-lable">手机验证码*</span>
                    </template>
                    <template v-slot:append>
                      <q-btn @click="getPhoneCode()" slot="append" outline class="code" :label="codeText"/>
                    </template>

                    <template v-slot:hint>
                      <span class="input-hint">请输入您的手机验证码</span>
                    </template>
                  </q-input>
                    <q-btn :loading="loading"  unelevated class="button-login" label="确认修改" type="submit"></q-btn>
                </q-form>
            </div>
        </div>
    </div>
</template>

<script>
    import { account,verOldPwd,verNewPwd,verConfirmPwd,phone,email } from "@/assets/Validation";
    export default {
        name: "ForgetPassword",
        data(){
            return {
                register:true,// 是否是注册页面
                loading: false,// 登录加载状态
                loginType: 0, // 登录方式 0 密码登录 1手机号 2微信扫码
                codeText:'获取验证码',
                ruleForm: {
                    phone: '',
                    code: ''
                },
                rules: {
                    phone: [
                        {required: true, message: '请输入手机号码', trigger: 'blur'},
                        {validator: phone, trigger: 'blur'},
                    ],
                    code: [
                        {required: true, message: '请输入验证码', trigger: 'blur'},
                        {type: 'number', message: '请输入数字验证码'}
                    ]
                },
                identity:null,
                options:[{ label: '老师', value: '0' }, { label: '学生', value: '1' }],
                mobile:null,
                pwd:true,
                name: '',
                oldPwd:'',
                age: null,
                accept: false,
                newPwd:'',
                confirmPwd:'',
                login:{
                    account:'',
                    password:''
                }
            }
        },
        methods: {
            // 登录方式选择
            loginTypeClick(index) {
                console.log(index)
                this.loginType = index
            },
            // 登录方式
            wxLogin(){
                this.$parent.register = 1
                this.$parent.loginType = 2
            },
            // 获取手机验证码
            getPhoneCode() {
                let that = this
                // eslint-disable-next-line no-unused-vars
                let times = null
                // eslint-disable-next-line no-empty

                console.log(this.name)
                console.log(this.onSubmit())

                    this.$http.post('ems/send',{
                        email:that.name,
                        event:'resetpwd'
                    }).then(res =>{
                        console.log(res)
                        this.$q.notify({
                            type: 'positive',
                            position:'top',
                            timeout:'500',
                            message: `已发送验证码，请注意查收`
                        })
                        if (this.codeText == '获取验证码'){
                            this.codeText = 5
                            times = setInterval(() => {
                                that.codeText--
                                console.log(that.codeText)
                                if(that.codeText === -1){
                                    clearInterval(times)
                                    that.codeText = '获取验证码'
                                }
                            },1000)
                            // eslint-disable-next-line no-empty
                        }
                    }).catch(err =>{
                        this.$q.notify({
                            type: 'negative',
                            position:'top',
                            timeout:'500',
                            message: err.msg
                        })
                    })



            },
            // 表单提交
            submitForm(formName) {
                console.log(formName)
                this.$http.post('user/login',{
                    account:this.login.account,
                    password:this.login.password,
                }).then(res =>{
                    console.log(res)
                    localStorage.setItem('token',res.data.userinfo.token)
                    localStorage.setItem('user_id',res.data.userinfo.user_id)
                    this.$q.notify({
                        type: 'positive',
                        position:'top',
                        timeout:'500',
                        message: `登录成功`
                    })
                    this.$router.push({path:'/'})
                })
            },
            onSubmit () {
                this.$refs.myForm.validate().then(success => {
                    if (success) {
                        this.loading = true
                        this.$http.post('user/register',{
                            identity:this.identity.value,
                            username:this.name,
                            password:this.newPwd,
                            repassword:this.newPwd,
                            mobile:this.mobile
                        }).then(res =>{
                            console.log('注册接口',res)
                            this.loading = false
                            localStorage.setItem('token',res.data.userinfo.token)
                            localStorage.setItem('user_id',res.data.userinfo.user_id)
                            this.$router.push({path:'/'})
                        }).catch(err =>{
                            console.log(err)
                            this.loading = false
                            this.$q.notify({
                                type: 'negative',
                                position:'top',
                                timeout:'500',
                                message: err.msg
                            })
                        })
                        // 是的，模型是正确的
                    }
                    else {
                        // 哦，不，用户至少
                        // 填写了一个无效值
                        console.log('11111asdasdasd')
                        return 1
                    }
                })
            },
            verAccount (val) {
                return new Promise((resolve) => {
                    if (!account(val)){
                        resolve(!!val || '请输入账号')
                    }else {
                        resolve()
                    }
                })
            },
            verOldPwd(val){
                return new Promise((resolve) => {
                    if (!verOldPwd(val)){
                        resolve(!!val || '请输入原密码')
                    }else {
                        resolve()
                    }
                })
            },
            verEmail(val){
                return new Promise((resolve) => {
                    if (!email(val)){
                        resolve(!!val || '请输入正确的邮箱')
                    }else {
                        resolve()
                    }
                })
            },
            verNewPwd(val){
                return new Promise((resolve) => {
                    if (!verNewPwd(val)){
                        resolve(!!val || '请输入新密码')
                    }else {
                        resolve()
                    }
                })
            },
            verConfirmPwd(val){
                return new Promise((resolve) => {
                    console.log(verConfirmPwd(val,this.newPwd))
                    if (verConfirmPwd(val,this.newPwd) === 0){
                        resolve('请再次输入新密码')
                    }else if (verConfirmPwd(val,this.newPwd) === 1) {
                        console.log('两次密码不一致')
                        resolve('两次密码不一致')
                    }else {
                        resolve()
                    }
                })
            },
            verPhone(val){
                return new Promise((resolve) => {
                    if (!phone(val)){
                        resolve(!!val || '请输入正确的手机号')
                    }else {
                        resolve()
                    }
                })
            },
            onReset () {
                this.name = null
                this.age = null
                this.accept = false
            }
        }
    }
</script>

<style scoped>

</style>